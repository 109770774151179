import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import './faq.css';

export default function Example() {
    return (
        <div id="FAQ" className="faq">
        <h2>F.A.Q</h2>
        <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How do I connect my Metamask account to your site?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Once on the site you need to connect your metamask to the site and look for the green 'connected' sign on your metamask account. If you have a yellow 'not connected' sign then you need to click on the yellow button and connect. 



Once connected the page will ask you to be connected to the Matic Mainnet. If you already know what this is then skip this step. If not then you need to follow the steps and add Polygon-Mainnet as a custom RPC here: https://docs.matic.network/docs/develop/metamask/config-polygon-on-metamask/



On mobile you need to go to settings > networks > add custom RPC and fill out the same way as described in that guide.



Once added, in Metamask adjust the Network at the top from Ethereum to the new Polygon/Matic Mainnet and your wallet will be connected to the mint page properly
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                       How do I get wETH in my Metamask account?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Our tokens are on the Polygon/MATIC network which means that if you are accustomed to Zed Run then you will already have wETH in your account or know how to get wETH. 



If you do not know then here is a short guide that includes setting up Metamask, logging into zed and converting Eth to wETH: https://zombie.racing/how-to-set-up-your-zed-run-stable
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I mint, once my Metamask is setup?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    There is an option to select the number of tokens to mint and a submit button. You will not be able to purchase more than the amount of wETH in your wallet and it will require two signatures to complete. The first is to allow the relayer we are using for the minimal MATIC gas fees to purchase on your behalf. The second is to send the transaction to the minting contract. 



Once you have signed these two the mint will take around 10 seconds and a successful notification will appear in the top right hand corner. The tokens may take around 5 minutes to appear in your Opensea account and revealed instantly!



On Opensea if you are seeing just the rotating ZRC symbol then you need to refresh the metadata to pull update. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I buy, sell or swap tokens on the secondary market?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, we will also have a dedicated channel giving you the opportunity to buy, sell and swap tokens. There will be additional utility added to holding tokens or even completing token sets so holding may be as valuable as re-selling.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                     Will tokens retain or increase in value?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We anticipate a number of tokens which are more rare to immediately increase in value on the resell market. Additionally, owning tokens in the future will allow you to access unique utility within ZRC. More of that will be released at a later date.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                     Can I use my ZED balance to mint the token?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, you can use your ZED balance to mint a token on our website.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                      I login through ZED with my email and don't have a metamask wallet, what should I do?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    You need to set up a metamask wallet to connect to the site. If you do not already have one set up there is a guide here: https://zombie.racing/how-to-set-up-your-zed-run-stable/
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </div>
    );
}