import React from "react";
import "./intro.css";
import TokenVid from "../images/TokenVid.mp4"



function Intro() {
return (
    <div className="Intro">
<div className="text">
<span className="red">#ZRC</span><br/><br/>
The Zombie Racing Club is a series of 12 unique tournaments with a season finale. Each tournament has been designed to allow all horses from across the ZED platform to compete. A prize pool of $250,000 is up for grabs including cash and high value NFTs. ZRC members can also access a whole host of extra rewards including the mint suite


<br/><br/>
<a href="https://discord.gg/ZdPWM45Muy">
<button class="large">Join Discord</button></a>


</div>
<video autoplay="true" loop="true" width="100%">

    <source src={TokenVid}
            type="video/mp4"/>

    Sorry, your browser doesn't support embedded videos.
</video>
    </div>
);
}

export default Intro;