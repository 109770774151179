import React from 'react';
import './tokens.css';
import Buterin from './images/Buterin_Mystical.png';
import Szabo from './images/Szabo_Moon.png';
import Nakamoto from './images/Nakamoto_Fiery.png';
import Finney from './images/Finney_Wild.png';
import Wwalker from './images/ZB.mp4'

export default function Tokens() {
    return (
        <div id="NFT" className="token">
        <h2>Tokens</h2>
        <div className="tokenholder">
            <div className="individual">
                <img src={Buterin}/>
                <h3>Buterin</h3>
                <p>Common</p>
            </div>
            <div className="individual">
                <img src={Finney}/>
                <h3>Finney</h3>
                <p>Uncommon</p>
            </div>
            <div className="individual">
                <img src={Szabo}/>
                <h3>Szabo</h3>
                <p>Rare</p>
            </div>
            <div className="individual">
                <img src={Nakamoto}/>
                <h3>Nakamoto</h3>
                <p>Super Rare</p>
            </div>
        </div>
        <h2 id="MINT">Mint Suite For Token Holders</h2>
        <div className="mintsuite">
        <div>
        <video autoplay="true" loop="true" width="100%">

    <source src={Wwalker}
            type="video/mp4"/>

    Sorry, your browser doesn't support embedded videos.
</video>
        </div>
        <div className="details">
        The ZRC Mint Suite allow token holders with an opportunity to create unique NFTs with utility. 


<br/><br/>
<strong>Drop 1 - NFT Companion Card - October 14th</strong> <br/><br/>
<ul>
<li>Uniquely designed companion card NFTs, the differentiation in design is based on the colour of your horse.</li>
<li>Your horse ID will be cross referenced with Metamask in order to auto populate and mint your companion card.</li>
<li>After token holders & set holders have minted their tokens, the opportunity to purchase additional cards from our store will be unlocked.</li>
<li>HINT - Season 2 will include card only tournaments where you can only enter horses which you have a card for.</li>
</ul>

Drop 2 - November 14th 

<br/><br/>

Drop 3 - December 14th 
        </div>
        </div>
        </div>
    );
}