import React from "react";
import Web3_EIP2771_EIP712Sign from "./components/Web3_EIP2771_EIP712Sign";
import "./App.css";
import {
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Header from "./components/header";
import Intro from "./components/intro";
import Faq from "./components/faq"
import Tokens from "./components/tokens";
import Footer from "./components/footer";



function App() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="App" id="container">
          <Header />
          
        <Web3_EIP2771_EIP712Sign />
      <NotificationContainer />
      <Intro/>
      <Faq/>
      <Tokens/>
      <Footer/>
    </div>
  );
}

export default App;
