import React from "react";
import "./header.css";
import logo from "./images/logo.webp"
import MobileMenu from "./mobilemenu";

function Header() {
return (
    <div className="Header">
        <img src={logo}/>

<MobileMenu/>




    </div>
);
}

export default Header;