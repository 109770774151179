import React from "react";
import './mobilemenu.css'
function MobileMenu() { {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
        <div class="head">
        <input class="menu-btn" type="checkbox" id="menu-btn" />
        <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
        <ul class="menu">
        <li><a href="#">Home</a></li>
        <li>   <a href="#FAQ">FAQ</a></li>
        <li> <a href="#MINT">NFT Suite</a></li>
        <li> <a href="#SOCIALS">Socials</a></li>
        </ul>
      </div>
    );
  }
}

export default MobileMenu;